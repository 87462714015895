import React from "react";
import compose from "recompose/compose";
import { defineMessages, useIntl } from "react-intl";
import classNames from "classnames";
import EnhanceMenuNavigation from "theme/layouts/Header/MenuNavigation/EnhanceMenuNavigation";
import MenuNavigationQuery from "theme/layouts/Header/MenuNavigation/MenuNavigationQuery.gql";
import IconButton from "theme/components/atoms/Button/IconButton";
import Icon from "theme/components/atoms/Icon";
import MediaQuery from "theme/components/helpers/MediaQuery";
import Breakpoints from "theme/components/atoms/Breakpoints";
import useClickOutside from "theme/utils/useClickOutside";
import TrackingLink from "theme/components/atoms/Typography/Link/TrackingLink";

const messages = defineMessages({
  newProducts: {
    id: "layouts.CustomMenuNavigation.newProducts",
    defaultMessage: "New products",
  },
  promotions: {
    id: "layouts.CustomMenuNavigation.promotions",
    defaultMessage: "Promotions",
  },
  explodedViews: {
    id: "explodedViews",
    defaultMessage: "Exploded views",
  },
  everparts: {
    id: "everparts",
    defaultMessage: "Everparts",
  },
});

const getSubcategoryGridFractions = (subCategory) => {
  const maxColumnsNumber = 4;
  let gridFractions = Math.ceil(subCategory.children.length / maxColumnsNumber);

  if (subCategory.children.length <= 20) {
    return 5;
  }

  return gridFractions;
};

const DesktopSubCategoriesMenu = ({ handleClose, currentMainCategory }) => {
  return (
    <div
      className={classNames("desktop-sub-categories-menu", {
        "desktop-sub-categories-menu--open": currentMainCategory,
      })}
    >
      {currentMainCategory ? (
        <>
          <div className="desktop-sub-categories-menu__top-bar">
            <div className="desktop-sub-categories-menu__main-category-name">
              <TrackingLink
                eventName="category clicked"
                eventPayload={{ name: currentMainCategory.name }}
                to={{
                  pathname: currentMainCategory.path,
                }}
              >
                {currentMainCategory.name}
              </TrackingLink>
            </div>
            <div className="desktop-sub-categories-menu__close">
              <IconButton icon="cross" title="close" onClick={handleClose} />
            </div>
          </div>
          <div className="desktop-sub-categories-menu__sub-categories">
            {currentMainCategory.children.map((subCategory) => (
              <div className="sub-category">
                <div className="sub-category__name" onClick={handleClose}>
                  <TrackingLink
                    eventName="category clicked"
                    eventPayload={{ name: subCategory.name }}
                    to={{
                      pathname: subCategory.path,
                    }}
                  >
                    {subCategory.name}
                  </TrackingLink>
                </div>
                <div
                  className="sub-category__categories"
                  /** 
                Big hack to control grid-template-rows value depending on subCategory children length,
                this is the only way I found to respect the model.
              */
                  style={{
                    gridTemplateRows: `repeat(${getSubcategoryGridFractions(
                      subCategory
                    )}, 1fr)`,
                  }}
                >
                  {subCategory.children.map(({ name, path }) => (
                    <span
                      className="sub-category__item"
                      key={name}
                      onClick={handleClose}
                    >
                      <TrackingLink
                        eventName="category clicked"
                        eventPayload={{ name }}
                        to={{
                          pathname: path,
                        }}
                      >
                        {name}
                      </TrackingLink>
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

const CategoryItem = ({ selected, hasChildren, name, icon, onClick }) => {
  return (
    <div
      className={classNames("category-item", {
        "category-item--selected": selected,
        "category-item--has-children": hasChildren,
      })}
      {...(onClick && { onClick })}
    >
      <div className="category-item__label">{name}</div>
      {icon && <Icon icon={icon} title="icon" />}
    </div>
  );
};

const GoTo = ({ goTo, label }) => {
  return (
    <div className="go-to" onClick={goTo}>
      <Icon icon="chevron-left" title="previous" />
      {label}
    </div>
  );
};

const CustomNavigationMenu = ({
  categories,
  menuNavigationOpen,
  setMenuNavigationOpen,
}) => {
  const intl = useIntl();

  const [currentMainCategory, setCurrentMainCategory] = React.useState(null);
  const [navigationStack, setNavigationStack] = React.useState([]);

  const navigationMenuRef = React.useRef();

  useClickOutside(navigationMenuRef, () => {
    handleClose();
  });

  const appendToNavigationStack = (category) => {
    setNavigationStack((previousNavigationStack) => [
      ...previousNavigationStack,
      category,
    ]);
  };

  const goToPrevious = () => {
    setCurrentMainCategory(navigationStack[navigationStack.length - 1]);
    const updatedNavigationStack = [...navigationStack];
    updatedNavigationStack.pop();
    setNavigationStack(updatedNavigationStack);
  };

  const goToMain = () => {
    setCurrentMainCategory(null);
    setNavigationStack([]);
  };

  const handleClose = () => {
    if (currentMainCategory) {
      setCurrentMainCategory(null);
    }
    if (menuNavigationOpen) {
      setMenuNavigationOpen(false);
    }
  };

  return (
    <div ref={navigationMenuRef} className="custom-menu-navigation-container">
      <div
        className={classNames("custom-menu-navigation", {
          "custom-menu-navigation--open": menuNavigationOpen,
        })}
      >
        <MediaQuery minWidth={Breakpoints.horizontalTablet}>
          {(isLaptop) =>
            isLaptop || !currentMainCategory ? (
              <>
                <div className="new-category-item" onClick={handleClose}>
                  <TrackingLink
                    eventName="category clicked"
                    eventPayload={{
                      name: intl.formatMessage(messages.newProducts),
                    }}
                    to={{
                      pathname: "/nouveautes.html",
                    }}
                  >
                    <CategoryItem
                      name={intl.formatMessage(messages.newProducts)}
                      icon="primary-new-tag"
                    />
                  </TrackingLink>
                </div>
                <div className="discount-category-item" onClick={handleClose}>
                  <TrackingLink
                    eventName="category clicked"
                    eventPayload={{
                      name: intl.formatMessage(messages.promotions),
                    }}
                    to={{
                      pathname: "/promotions.html",
                    }}
                  >
                    <CategoryItem
                      name={intl.formatMessage(messages.promotions)}
                      icon="primary-percentage"
                    />
                  </TrackingLink>
                </div>
                <div className="everparts-item" onClick={handleClose}>
                  <TrackingLink
                    eventName="category clicked"
                    eventPayload={{
                      name: intl.formatMessage(messages.everparts),
                    }}
                    to={{
                      pathname: "/everparts",
                    }}
                  >
                    <CategoryItem
                      name={intl.formatMessage(messages.everparts)}
                      icon="wrench"
                    />
                  </TrackingLink>
                </div>

                {categories.map((category) => (
                  <CategoryItem
                    key={`main-category-${category.id}`}
                    selected={category.id === currentMainCategory?.id}
                    name={category.name}
                    icon="chevron-right"
                    onClick={() => {
                      if (category.id === currentMainCategory?.id) {
                        handleClose();
                      } else {
                        appendToNavigationStack(currentMainCategory);
                        setCurrentMainCategory(category);
                        window.scrollTo(0, 0);
                      }
                    }}
                  />
                ))}
              </>
            ) : (
              <>
                {navigationStack.length === 2 && (
                  <GoTo
                    goTo={goToMain}
                    label={navigationStack[navigationStack.length - 1].name}
                  />
                )}
                <GoTo goTo={goToPrevious} label={currentMainCategory?.name} />
                {currentMainCategory.children.map((category) => {
                  const [selected, hasChildren] = [
                    category.id === currentMainCategory?.id,
                    category?.children?.length > 0,
                  ];
                  const categoryItemComponent = (
                    <CategoryItem
                      key={`sub-category-${category.id}`}
                      selected={selected}
                      hasChildren={hasChildren}
                      name={category.name}
                      {...(hasChildren && { icon: "chevron-right" })}
                      onClick={() => {
                        if (hasChildren) {
                          appendToNavigationStack(currentMainCategory);
                          setCurrentMainCategory(category);
                          window.scrollTo(0, 0);
                        } else {
                          appendToNavigationStack([]);
                          setCurrentMainCategory(null);
                          setMenuNavigationOpen(false);
                        }
                      }}
                    />
                  );
                  return hasChildren ? (
                    categoryItemComponent
                  ) : (
                    <TrackingLink
                      eventName="category clicked"
                      eventPayload={{
                        name: category.name,
                      }}
                      to={{
                        pathname: category.path,
                      }}
                    >
                      {categoryItemComponent}
                    </TrackingLink>
                  );
                })}
              </>
            )
          }
        </MediaQuery>
      </div>
      <DesktopSubCategoriesMenu
        handleClose={handleClose}
        currentMainCategory={currentMainCategory}
      />
    </div>
  );
};

export default compose(
  EnhanceMenuNavigation({
    MenuNavigationQuery,
  })
)(CustomNavigationMenu);
