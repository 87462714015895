import React from "react";
import compose from "recompose/compose";
import branch from "recompose/branch";
import { Redirect, withRouter } from "react-router";
import { graphql } from "react-apollo";
import Loadable from "theme/components/helpers/Loadable";
import CheckAuthQuery from "./CheckAuthQuery.gql";
import { guestPaths } from "config/website";

const preventGuestAccess = () => {
  return compose(
    graphql(CheckAuthQuery, {
      props: ({ data }) => ({
        loading: data.loading,
        me: !data.loading && data.me,
        error: data.error,
      }),
    }),
    withRouter,
    Loadable((props) => !props.loading),
    branch(
      (props) => {
        return (
          (props.me && props.me.id !== null) ||
          guestPaths.includes(props.location.pathname)
        );
      },
      (BaseComponent) => BaseComponent,
      () => () => {
        return (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        );
      }
    )
  );
};

export default preventGuestAccess;
