import React from "react";
import { compose, withProps } from "recompose";
import { withRouter } from "react-router";
import { disabledPagePaths } from "config/website";
import NotFound from "theme/modules/PageError/NotFound";

const preventDisabledPagesAccess = () => {
  return compose(
    withRouter,
    withProps((props) => {
      const userIsTryingToAccessDisabledPage = disabledPagePaths.includes(
        props.location.pathname
      );

      if (userIsTryingToAccessDisabledPage) {
        /* We don't redirect here to preserve URL */
        return { children: <NotFound /> };
      }
    })
  );
};

export default preventDisabledPagesAccess;
