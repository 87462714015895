import React, { useRef, useContext, useEffect, useState } from "react";
import classnames from "classnames";
import { compose, lifecycle } from "recompose";
import { Helmet } from "react-helmet-async";
import { injectIntl, FormattedMessage } from "react-intl";
import { withCookies } from "react-cookie";
import { initAnalytics } from "web/core/analytics";
import config from "config/website";
import useFullUrl from "web/core/shop/useFullUrl";
import ErrorBoundary from "theme/components/helpers/ErrorBoundary";
import PageError from "theme/modules/PageError";
import withRefreshing from "./withRefreshing";
import CustomNavigationMenu from "./CustomNavigationMenu";
import withNavigationMenu from "./CustomNavigationMenu/withNavigationMenu";
import preventGuestAccess from "theme/modules/Router/preventGuestAccess";
import preventDisabledPagesAccess from "theme/modules/Router/preventDisabledPagesAccess";
import { WebSocketContext } from "theme/modules/WebSocket/WebSocketProvider.js";

const GenericLayout = ({
  header,
  children,
  intl,
  refreshing = false,
  isGuest = false,
  menuNavigationOpen,
  setMenuNavigationOpen,
  ...props
}) => {
  const url = useFullUrl("/");
  const contentRef = useRef();
  const [isWebSocketConnected, setWebSocketConnected] = useState(false);
  const { connectWebSocket } = useContext(WebSocketContext);

  useEffect(() => {
    if (props.me && props.me.id) {
      connectWebSocket(props.me.id).then(() => {
        setWebSocketConnected(true);
      });
    }
  }, [props.me, connectWebSocket]);

  if (!isWebSocketConnected && !isGuest) {
    return null;
  }

  return (
    <>
      <button
        className="sr-only sr-focusable"
        onClick={() => {
          contentRef.current && contentRef.current.focus();
        }}
      >
        <FormattedMessage
          id="layouts.GenericLayout.goToMainContent"
          defaultMessage="Go to main content"
        />
      </button>
      <div
        className={classnames("wrapper", { "wrapper--refreshing": refreshing })}
        itemScope
        itemType="http://schema.org/WebSite"
      >
        {/* this meta is outside Helmet because it describes the itemType="WebSite" */}
        <meta itemProp="url" content={url} />

        <Helmet defaultTitle={config.defaultTitle}>
          <html lang={intl.locale} />
          <meta name="language" content={intl.locale} />
          <meta name="robots" content="Index,Follow" />
          <meta name="description" content={config.defaultDescription} />
          {config.themeColor ? (
            <meta name="theme-color" content={config.themeColor} />
          ) : null}
        </Helmet>
        {typeof header === "function" &&
          header({ menuNavigationOpen, setMenuNavigationOpen })}
        <div className="main-content" tabIndex="-1" ref={contentRef}>
          {!isGuest && (
            <CustomNavigationMenu
              menuNavigationOpen={menuNavigationOpen}
              setMenuNavigationOpen={setMenuNavigationOpen}
            />
          )}
          <ErrorBoundary>
            {({ hasError }) => (hasError ? <PageError /> : children)}
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};

export default compose(
  preventGuestAccess(),
  preventDisabledPagesAccess(),
  injectIntl,
  withRefreshing(),
  withCookies,
  withNavigationMenu(),
  lifecycle({
    componentDidMount() {
      initAnalytics(this.props.cookies.get("authorizations"));
    },
  })
)(GenericLayout);
