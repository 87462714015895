import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import Logo from "theme/components/atoms/Logo";
import { withShop } from "web/core/shop/ShopContext";
import StoreViewSelector from "theme/modules/User/StoreViewSelector";

import GenericLayout from "./GenericLayout";

const GuestLayout = (props) => {
  const header = (props) => (
    <header className="header">
      <div className="header__container container">
        <div className="header__logo-container">
          <Logo classes="header__logo" />
        </div>
        <StoreViewSelector storeView={props.user?.storeView} />
      </div>
    </header>
  );

  return (
    <GenericLayout location={props.location} header={header} isGuest={true}>
      <div className="mdl-layout__content mdl-layout__content--guest">
        {props.children}
      </div>
    </GenericLayout>
  );
};

GuestLayout.propTypes = {
  children: PropTypes.node,
};

export default compose(withShop())(GuestLayout);
